import { createI18Context } from '@padcom/vue-i18n'
import {ALL_LOCALES, EnGB, Locale} from "../../Constants/locales";
export * from './translationService';
const loadLocaleMessages = (locales: Locale[]) => {
    const dictionaryModule: Record<string, { default: any }> = import.meta.glob('../../Translations/*.json', { eager: true });

    const messages = {} as any;
    for (const path in dictionaryModule) {
        if (path) {
            const matched = path.match(/([a-z0-9-_]{2,6})\./i);
            if (matched && matched.length > 1) {
                const locale = matched[1] as Locale;
                if(locales.includes(locale)){
                    messages[locale] = dictionaryModule[path]?.default;
                }
            }
        }
    }

    return messages;
};

const deepMergeWithSpread = (obj1:Record<Locale,any>, obj2:Record<Locale,any>):Record<Locale , Record<string,any>> => {
    const result: Record<Locale, any> = { ...obj1 };
    for (let key in obj2) {
        if (obj2.hasOwnProperty(key)) {
            if (obj2[(key as Locale)] instanceof Object && obj1[(key as Locale)] instanceof Object) {
                result[(key as Locale)] = deepMergeWithSpread(obj1[(key as Locale)], obj2[(key as Locale)]);
            } else {
                result[(key as Locale)] = obj2[(key as Locale)];
            }
        }
    }
    return result;
}

const mergeMessages = (
    obj1: Record<Locale, Record<string,string>>,
    obj2: Record<Locale, Record<string,string>>): Record<Locale , Record<string,any>>  => deepMergeWithSpread(obj1, obj2);


export const bootstrapI18n = (args: {locale: Locale, customMessages: Record<Locale, Record<string, string>>}) => {
    const allMessages = mergeMessages(loadLocaleMessages([args.locale, ...ALL_LOCALES]),args.customMessages);
    return createI18Context({
        locale: args.locale,
        fallbackLocale: EnGB,
        messages: allMessages,
    });
}
