import {InvalidWidgetConfiguration} from "../Errors/InvalidWidgetConfiguration";
import {FLOATING_WIDGET_TYPE} from "../../../Contracts/WidgetTypes/FloatingWidgetType";
import {isValidFloatingWidgetConfiguration} from "./FloatingWidgetConfigurationValidator";
export const isValidWidgetConfiguration = (config: any) => {
    if(!config.id){
        throw InvalidWidgetConfiguration.fromMissingWidgetId();
    }

    switch (config.type) {
        case FLOATING_WIDGET_TYPE:
            return isValidFloatingWidgetConfiguration(config);
        default:
            throw InvalidWidgetConfiguration.fromInvalidWidgetType([ FLOATING_WIDGET_TYPE]);
    }
}