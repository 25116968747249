<script setup lang="ts">
// MOVE TO BOOTSTRAP
import {IUseWidgetConfiguration, useWidgetConfiguration} from "../../composables/useWidgetConfiguration";
import { provide } from "vue";
import {USE_WIDGET_CONFIGURATION} from "../../Constants/di";
import {getLogger} from "../../Services/LoggingService";
import Widget from '../../Widgets/ModalWidget/index.vue';
const logger = getLogger();
const props = defineProps<{config: any}>();
try {
  const widgetConfiguration = useWidgetConfiguration(props.config);
  provide<IUseWidgetConfiguration>(USE_WIDGET_CONFIGURATION,widgetConfiguration );
} catch (e: any){
  logger.error(e);
}
</script>

<template>
<widget
  :widget-id="config.id"
/>
</template>

<style scoped>

</style>