import posthog, {PostHogConfig} from 'posthog-js';
import {IInsights} from "../../Contracts/IInsights";
import {GlobalEventBus, SESSION_EVENTS} from "../GlobalEventBus";
import {WidgetLocation} from "../../Widgets/ModalWidget/Composables/WidgetNavigation/useWidgetNavigation";
import {BUTTON_VIEW_ROUTE_NAME} from "../../Widgets/ModalWidget/Composables/WidgetNavigation/Routes/ButtonViewRoute";
import ROUTE_NAMES from "../../Widgets/ModalWidget/Composables/WidgetNavigation/Routes/constants";
import {WIDGET_STATE_EVENTS} from "../../Events/Widget/State";
import {Widget} from "../../Contracts/WidgetTypes";

export class PosthogAnalytics implements IInsights {

    private eventBus: GlobalEventBus;
    public constructor({logger, eventBus}: {logger: ILogger, eventBus: GlobalEventBus}) {
        this.eventBus = eventBus;

        const active = import.meta.env.VITE_POSTHOG_HOST && import.meta.env.VITE_POSTHOG_PROJECT;
        const postHogConfig: Partial<PostHogConfig> = {
            api_host: import.meta.env.VITE_POSTHOG_HOST,
            autocapture: false,
            capture_pageview: false,
            capture_pageleave: false,
            on_request_error() {
                // logger.error(new Error(`Posthog request error: ${error}`));
            }
        }

        if(active){
            try {
                posthog.init(import.meta.env.VITE_POSTHOG_PROJECT, postHogConfig);
                this.listen();
            } catch (e) {
                logger.error(e as Error);
            }
        }

    }
    mapNavigationChangedEvent = (route: string, details: WidgetLocation) => {
        switch (route) {
            case BUTTON_VIEW_ROUTE_NAME:
                return {
                    event: 'element_view',
                    properties: {
                        element: 'floating_button.button',
                        ...details.props
                    }
                }
            case ROUTE_NAMES.EXPERIENCE_LIST:
                return {
                    event: 'page_view',
                    properties: {
                        page_title: 'floating_widget.list_widget',
                        ...details.props
                    }
                }
            case ROUTE_NAMES.DATE_TIME_SELECTION:
                return {
                    event: 'page_view',
                    properties: {
                        page_title: 'floating_widget.experience_details',
                        ...details.props
                    }
                }
            default:
                return {
                    event: 'page_view',
                    properties: {
                        ...details.props
                    }
                }
        }
    }

    listen = () => {

        const shouldTrackWidgetEvents = (config: Widget) => config.options.collectTraces;
        this.eventBus.listen(({event, data}) => {
            if(event === SESSION_EVENTS.SESSION_CREATED){
                this.identify(data.sessionId);
            }

            if(event === WIDGET_STATE_EVENTS.WIDGET_NAVIGATION_CHANGED){
                if(!shouldTrackWidgetEvents(data)){return}
                const {event, properties} = this.mapNavigationChangedEvent( data.name, data);
                this.trackEvent(event,properties);
            }

            if(event === WIDGET_STATE_EVENTS.WIDGET_MOUNTED){
                if(!shouldTrackWidgetEvents(data)){return}
                this.trackEvent("widget_loaded", data);
            }
        })
    }
    trackEvent = (event: string, properties?: Record<string, any>) => {
        posthog.capture(event, properties);
    }
    identify = (userId: string) => {
        posthog.identify("user_"+userId);
    }
}