import {Availability, Experience, MediaImage} from "./types";
import {mapExperience} from "./Reducers/ExperienceReducer";
import {DefaultApi} from "@bookingkit-private/api-v4";
import InvalidApiV4ResponseError from "./Errors/InvalidApiV4ResponseError";
import {fromApiAvailability} from "./Reducers/AvailabilityReducer";
import {format} from "date-fns";
import languages from "../../Widgets/ModalWidget/Composables/Constants/Languages";
import {EnGB} from "../../Constants/locales";
import {fromApiExperienceMedia} from "./Reducers/ExperienceMediaReducer";


type GetAvailabilitiesInput = {
    experienceId: string,
    startAt: Date,
    asc?:1 | 0 | boolean,
}
export class ExperienceService  {
    private readonly connection: DefaultApi;
    private readonly widgetId :string
    constructor(widgetId:string, connection:DefaultApi) {
        this.widgetId = widgetId;
        this.connection =  connection;
    };


    async getAvailabilities(getAvailabilitiesInput: GetAvailabilitiesInput): Promise<Availability[]> {
        const startAt = format(getAvailabilitiesInput.startAt, "yyyy-MM-dd'T'HH:mm:ss.SSSXXX");
        const { data } = await this.connection.listWidgetExperiencesAvailabilities(
            this.widgetId,
            getAvailabilitiesInput.experienceId,
            startAt,
            undefined, undefined, 50, getAvailabilitiesInput.asc as boolean )
        if(!data || !data.data) {
            throw InvalidApiV4ResponseError.fromMissingProperty('data', 'object', data);
        }
        return data.data.map(fromApiAvailability);

    }

    async getExperiences(experienceIds?:string):Promise<Experience[]> {
        const supportedLanguages = languages.map((language) => language.value === EnGB ? 'en-US' :language.value);
        const { data } = await this.connection.listWidgetExperiences(this.widgetId, experienceIds, supportedLanguages.toString());
        if(!data?.data) {
            throw InvalidApiV4ResponseError.fromMissingProperty('data', 'object', data);
        }
        return data?.data?.map(mapExperience) ?? [];
    }

    async getExperienceMedia(experienceId?: string): Promise<MediaImage[]> {
        const {data} = await this.connection.listWidgetExperienceMedia(this.widgetId, experienceId as string);
        if (!data?.data) {
            throw InvalidApiV4ResponseError.fromMissingProperty('data', 'object', data);
        }
        return  data?.data?.map(fromApiExperienceMedia).sort((a, b) => (a.sort ?? 0 )- (b?.sort ?? 0));

    }

}

export default { ExperienceService };