import {SessionService} from "./SessionService/SessionService";
import {IInsights} from "../Contracts/IInsights";
import {getLogger} from "../Services/LoggingService";
import {CheckoutApi} from "./SessionService/CheckoutApi";
import {PosthogAnalytics} from "./InternalAnalytics/PosthogAnalytics";
import {GlobalEventBus} from "./GlobalEventBus";

declare global {
    interface Window {
        bookingkitServices: {
            eventBus: GlobalEventBus;
            sessionService: SessionService;
            loggerService: ILogger;
            internalAnalyticsService: IInsights;
        }
    }
}

const loggerService = getLogger();
const eventBus = new GlobalEventBus();
const sessionService =  new SessionService(
    {
        checkoutApi: new CheckoutApi({
            baseUrl:  import.meta.env.VITE_BK_CHECKOUT_API_URL as string
        }),
        loggerService: loggerService,
        eventBus
    });

const internalAnalyticsService = new PosthogAnalytics({
    logger: loggerService,
    eventBus
});

sessionService.init()
    .catch((e) => loggerService.error(e));

window.bookingkitServices = {
    sessionService,
    eventBus,
    loggerService,
    internalAnalyticsService
}