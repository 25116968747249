import {getLogger} from "../Services/LoggingService";
import {Widget} from "../Contracts/WidgetTypes";
import {provide} from "vue";
import {API_V4, LOGGER_SERVICE,} from "../Constants/di";
import {ExperienceService} from "../Services/BookingkitApi";
import makeBookingkitApiService from "./Factories/BookingkitApiFactory";
import {bootstrapI18n} from "../Services/TranslationService";

export default (config: Widget) => {
    // Should init services
    const logger = getLogger();

    // Should bind all dependencies here using built in vue provide/inject mechanism
    // Unfortunately, we can't have to do "dependency resolution" manually here
    // To keep the bundle small
    // Further improvements here would be to load the dependencies dynamically
    // Eg we could avoid loading Posthog Library if the tracking is disabled
    // This would make the bootstrap async, we would need to handle the async cycle

    provide<ILogger>(LOGGER_SERVICE, logger);

    provide<ExperienceService>(API_V4,
        makeBookingkitApiService({
            logger,
            widgetConfiguration: config
        })
    );

    // Self binding
    bootstrapI18n({locale: config.locale, customMessages: config.localisation});
};