import {TriggerConfigurationError} from "../Errors/TriggerConfigurationError";
import {TriggerStyle} from "../../../Contracts/WidgetTypes/Common/TriggerStyle";

const validateStyle = (style: any): style is TriggerStyle => {
    if(typeof style !== 'object') {
        throw TriggerConfigurationError.fromInvalidStyle(`expected object, got ${typeof style}`);
    }
    if(style?.position) {
        const validPositions = ['bottom-right', 'bottom-left', 'top-right', 'top-left', 'center-right', 'center-left'];
        if(!validPositions.includes((style as Record<string, any>)?.position)) {
            throw TriggerConfigurationError.fromInvalidStyle(`position must be one of ${validPositions.join(",")}, provided: ${style?.position}`);
        }
    }

    if(style?.icon) {
        const validIcons = ['ticket', 'voucher'];
        if(!validIcons.includes((style as Record<string, any>)?.icon)) {
            throw TriggerConfigurationError.fromInvalidStyle(`icon must be one of ${validIcons.join(",")}, provided: ${style?.icon}`);
        }
    }

    if(style?.buttonSize) {
        const validButtonSizes = ['small', 'medium'];
        if(!validButtonSizes.includes((style as Record<string, any>)?.buttonSize)) {
            throw TriggerConfigurationError.fromInvalidStyle(`icon must be one of ${validButtonSizes.join(",")}, provided: ${style?.buttonSize}`);
        }
    }

    if(style?.label) {
        if(typeof (style as Record<string, any>)?.label !== 'boolean' && typeof (style as Record<string, any>)?.label !== 'string') {
            throw TriggerConfigurationError.fromInvalidStyle(`label must be or a valid string or a boolean, provided: ${typeof style?.buttonSize}`);
        }
    }

    return true;
}
export const isValidWidgetTrigger = (trigger: any): trigger is TriggerStyle | HTMLElement[] => {
    if(trigger && (!(trigger instanceof HTMLElement) && !Array.isArray(trigger) && trigger?.constructor !== Object)) {
        throw TriggerConfigurationError.fromWrongType(typeof trigger);
    }
    if(Array.isArray(trigger)  && trigger.length === 0) {
        return true;
    }

    return validateStyle(trigger);
}