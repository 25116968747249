import {
    ExperienceResponseAvailabilityTypeEnum
} from "@bookingkit-private/api-v4/models/experience-response";
import {Experience, ExperienceOption} from "../types";
import ExperienceServiceError from "../Errors/ExperienceServiceError";
import {WidgetExperienceResponse} from "@bookingkit-private/api-v4";


export const mapExperience = (experience: WidgetExperienceResponse):Experience => {
    const {id, title, description, cover_image, availability_type, latest_booking_time_minutes} = experience;
    if (id === undefined) {
        throw ExperienceServiceError.fromMissingProperty('id', 'string', id);
    }
    if (availability_type === undefined) {
        throw ExperienceServiceError.fromMissingProperty('availability_type', 'string', availability_type);
    }
    if (title === undefined) {
        throw ExperienceServiceError.fromMissingProperty('title', 'string', title);
    }
    if (availability_type !== ExperienceResponseAvailabilityTypeEnum.OpeningHours && availability_type !== ExperienceResponseAvailabilityTypeEnum.TimeSlot) {
        throw ExperienceServiceError.fromInvalidProperty('availability_type', availability_type);
    }
    const getOptions = (): undefined | ExperienceOption[] => {
        if(experience.options) {
            return experience.options.map((option) => {
                return {
                    id: option.id || '',
                    title: option.title || '',
                    durationInMinutes: option.duration_in_minutes || 0,
                }
            });
        }
        return  undefined;
    }

    const getTranslations = ():{ locale: string; properties: Record<string, string>; }[]  => {

        return (experience.translations as { locale: string; properties: Record<string, string>; }[]) ?? [];
    }

    return {
        id: id as string,
        title: title || '',
        description: description || '',
        coverImage: cover_image,
        options: getOptions(),
        availabilityType: availability_type === ExperienceResponseAvailabilityTypeEnum.OpeningHours ? 'openingHours' : 'timeSlots',
        latestBookingTime: latest_booking_time_minutes || 0,
        translations: getTranslations(),
    }
};
