import { useI18n } from '@padcom/vue-i18n'
import {Locale, twoLetterLanguageCodes} from "../../Constants/locales";
import {computed, Ref} from "vue";



export const useTranslationService = () => {


    const {t,locale} = useI18n({
        useScope: 'global',
    });
    const twoLettersLocaleCode = computed(() => twoLetterLanguageCodes[locale.value as Locale]);
    const setCurrentLanguage = (language: Locale) => {
        locale.value = language;
    }
    return {
        currentLanguage: locale as Ref<Locale>,
        setCurrentLanguage,
        t,
        twoLettersLocaleCode: twoLettersLocaleCode.value
    }
}