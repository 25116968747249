<script setup lang="ts">
import { CSeparator, CCaption , BkLogo} from '@bookingkit/coppi'
import {onClickOutside} from "@vueuse/core";
import { X as CloseButton } from 'lucide-vue-next';
import {ref} from "vue";
import {useTranslationService} from "../../Services/TranslationService";
import LanguageSelector from "./Views/components/LanguageSelector.vue";

interface  ModalWidgetProps  {
  title: string,
  displayBKLogo?: boolean,
  widgetId:string,
}
const props = withDefaults(defineProps<ModalWidgetProps>(), {
  displayBKLogo: true,
});
const {t} = useTranslationService();
const modalView = ref(null);
const emits = defineEmits<{openLegacyWidgetAtExperience: string[], buyVouchers: string[], closeModal: []}>();
onClickOutside(modalView, () => {
  emits('closeModal');
});

const closeModal = () => {
  emits('closeModal');
};


</script>

<template>
  <div class="main-view" ref="modalView">
    <div class="header">
      <div class="title-section">
        <h5 class="view-title" >{{ title }}</h5>
        <button @click.stop="closeModal"
                data-bk-fw-test-id="floatingWidget.views.modal.close"
                :aria-label="t('floatingWidget.views.modal.accessibility.close', {pageTitle: title})">
          <close-button ></close-button>
        </button>
      </div>
      <c-separator class="seperator"></c-separator>
    </div>

    <div class="content">
     <slot></slot>
    </div>
    <div class="footer" v-if="props.displayBKLogo">
      <div>
        <c-caption type="two" class="logo-powered-by">{{t('floatingWidget.poweredBy.label')}}</c-caption>
        <bk-logo :complete="true" ></bk-logo>
      </div>
      <language-selector/>
    </div>
  </div>
</template>

<style scoped>
.main-view {
  position: fixed;
  z-index: 999999999;
  background-color: var(--fw-modal-background-colour);
  height: 90vh;
  width: 385px;
  max-height: 90vh;
  bottom: 0;
  right: 0;
  box-shadow:  var(--s0) calc(var(--s1) / 2) var(--s2) var(--color-neutral-4);
  border-radius: var(--s3);
  overflow-x: auto;
  margin: var(--s6);
}
.main-view::-webkit-scrollbar {
  width: var(--s1);
}

.main-view::-webkit-scrollbar-track {
  background-color: var(--fw-modal-background-colour);
  border-radius: var(--s3);
  margin-bottom: var(--s3);
}

.main-view::-webkit-scrollbar-thumb {
  background-color: var(--color-neutral-3);
}

.action-icon {
  background-color: #e0edff;
  width: 65px;
  height: 65px;
  margin: auto;
  display: flex;
  border-radius: 5px;
  flex: 1 0 auto;
}

.action-icon.urgent {
  background-color: rgba(73, 218, 33, 0.47);
  width: 65px;
}
.icon {
  margin: auto;
  stroke: #005dea;
}

.action-icon.urgent .icon{
  stroke: #ffffff;
}

.header {
  width: 100%;
  padding: var(--s4) 0;
  display: flex;
  flex-direction: column;
  gap: var(--s3);
  position: sticky;
  top: 0px;
  z-index: 9;
  background: var(--fw-modal-background-colour);
}
.title-section {
  display: flex;
  flex-direction: row;
  gap: var(--s3);
  justify-content: space-between;
  padding: 0 var(--s4);
  position: relative;
}

.view-title {
  padding: 0;
  text-align: center;
  vertical-align: middle;
  color: var(--color-neutral-10);
}
button {
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
}


.content {
  padding: var(--s1) var(--s4);
  min-height: 70vh;
}


.footer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin: var(--s7) var(--s4);
}

.logo-powered-by {
  margin: 0 var(--s2) var(--s1) 0;
  white-space: nowrap;
}

@media screen and (max-width: 770px) {
  .main-view {
    width: 100%;
    height: 100%;
    max-width: 100vh;
    max-height: 100vh;
    margin: 0;
    border-radius: 0;
  }
}
</style>