import {Widget} from "../../Contracts/WidgetTypes";
import {WidgetLocation} from "../../Widgets/ModalWidget/Composables/WidgetNavigation/useWidgetNavigation";
export const WIDGET_STATE_EVENTS = {
    WIDGET_NAVIGATION_CHANGED: 'widget_navigation_changed',
    WIDGET_MOUNTED: 'widgetMounted'
} as const;


export type WidgetStateEventConstructor = {
    [WIDGET_STATE_EVENTS.WIDGET_MOUNTED]: {},
    [WIDGET_STATE_EVENTS.WIDGET_NAVIGATION_CHANGED]: WidgetLocation,
}

export type WidgetStateEvents = {
    [K in keyof WidgetStateEventConstructor]: {
        event: K;
        data: WidgetStateEventConstructor[K] & Widget;
    };
};