import {TriggerStyle, WidgetStyle} from "./Common/TriggerStyle";
import {BaseWidgetConfiguration} from "./BaseWidget";
export const FLOATING_WIDGET_TYPE = 'floatingWidget' as const;

// #region FloatingWidgetType
export interface FloatingWidgetType extends BaseWidgetConfiguration {
    type:  typeof FLOATING_WIDGET_TYPE,
    /**
     *  The experiences for which to sell tickets
     */
    experiencesForTickets: string[],
    experiencesForVouchers: string[],
    trigger: TriggerStyle,
    widgetStyle: WidgetStyle,
    id: string,
}
// #endregion FloatingWidgetType

