import {WidgetLocationRaw} from "../contracts";
import {defineAsyncComponent, markRaw} from "vue";
import {ExperienceListViewProps} from "../../../Views/ExperienceListView.vue";
import ROUTE_NAMES from "./constants";
import LoadingState from "../../../Views/components/LoadingState.vue";
export class ExperienceListRoute implements WidgetLocationRaw {
    public name = ROUTE_NAMES.EXPERIENCE_LIST;
    public title = 'floatingWidget.views.ticketShop.title';
    public component = markRaw(defineAsyncComponent({
        loader: () => import( '../../../Views/ExperienceListView.vue'),
        loadingComponent : LoadingState
    }));
    public props: ExperienceListViewProps;
    public readonly layout = 'modal';
    constructor(props: ExperienceListViewProps) {
        this.props = props;
    }
}