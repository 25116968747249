import { ref } from "vue";
import bootstrap  from "../../bootstrap/bootstrap";
import {isValidWidgetConfiguration} from "./Validators";
import {hydrateDefaults} from "./Mappers";
import {Widget} from "../../Contracts/WidgetTypes";


// Here will be possible to add other configurations, eg: for "classic" kind of widgets


export type IUseWidgetConfiguration = ReturnType<typeof useWidgetConfiguration>;
export const useWidgetConfiguration = (widgetConfiguration: Partial<Widget>) => {

    isValidWidgetConfiguration(widgetConfiguration);

    const WIDGET_CONFIGURATION = ref<Required<Widget>>(hydrateDefaults(widgetConfiguration));
    bootstrap(WIDGET_CONFIGURATION.value);

    const getConfiguration = () => {
        return WIDGET_CONFIGURATION;
    }

    const updateConfiguration = (c: Partial<Widget>) => {
        isValidWidgetConfiguration(c);
        WIDGET_CONFIGURATION.value = hydrateDefaults(c);
        bootstrap(WIDGET_CONFIGURATION.value);
    }

    return {
        getConfiguration,
        updateConfiguration
    }
}