import {FloatingWidgetType} from "../../../Contracts/WidgetTypes/FloatingWidgetType";
import {FloatingWidgetConfigurationError} from "../Errors/FloatingWidgetConfigurationError";
import {isValidWidgetTrigger} from "./TriggerValidator";
import {isValidWidgetStyle} from "./WidgetStyleValidatior";
import {isValidCheckoutTheme} from "./WidgetOptionValidator";

export const isValidFloatingWidgetConfiguration = (configuration: any): configuration is FloatingWidgetType => {
    if(!configuration.experiencesForTickets && !configuration.experiencesForVouchers) {
        throw FloatingWidgetConfigurationError.noExperiencesForTicketsOrVouchers();
    }
    if(configuration.experiencesForTickets && !Array.isArray(configuration.experiencesForTickets)) {
        throw FloatingWidgetConfigurationError.experienceListIsNotAnArray('experiencesForTickets');
    }
    if(configuration.experiencesForVouchers && !Array.isArray(configuration.experiencesForVouchers)) {
        throw FloatingWidgetConfigurationError.experienceListIsNotAnArray('experiencesForVouchers');
    }
    if(configuration.experiencesForTickets && configuration.experiencesForVouchers) {
        if(configuration.experiencesForTickets.length === 0 && configuration.experiencesForVouchers.length === 0) {
            throw FloatingWidgetConfigurationError.noExperiencesForTicketsOrVouchers();
        }
    }
    if(configuration.trigger) {
        isValidWidgetTrigger(configuration.trigger);
    }
    if(configuration.widgetStyle) {
        isValidWidgetStyle(configuration.trigger);
    }
    if(configuration.checkoutTheme) {
        isValidCheckoutTheme(configuration.checkoutTheme);
    }
    return true;
}