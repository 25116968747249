import {FloatingWidgetType} from "../../../Contracts/WidgetTypes/FloatingWidgetType";
import {FloatingWidgetConfigurationError} from "../Errors/FloatingWidgetConfigurationError";
import {isValidWidgetTrigger} from "./TriggerValidator";
import {isValidWidgetStyle} from "./WidgetStyleValidatior";

export const isValidFloatingWidgetConfiguration = (configuration: any): configuration is FloatingWidgetType => {
    if(!configuration.experiencesForTickets && !configuration.experiencesForVouchers) {
        throw FloatingWidgetConfigurationError.noExperiencesForTicketsOrVouchers();
    }
    if(configuration.experiencesForTickets && !Array.isArray(configuration.experiencesForTickets)) {
        throw FloatingWidgetConfigurationError.experienceListIsNotAnArray('experiencesForTickets');
    }
    if(configuration.experiencesForVouchers && !Array.isArray(configuration.experiencesForVouchers)) {
        throw FloatingWidgetConfigurationError.experienceListIsNotAnArray('experiencesForVouchers');
    }
    if(configuration.trigger) {
        return isValidWidgetTrigger(configuration.trigger);
    }
    if(configuration.widgetStyle) {
        return isValidWidgetStyle(configuration.trigger);
    }
    return true;
}