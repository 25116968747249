import {ref} from "vue";
import {Experience} from "../../../Services/BookingkitApi/types";
import { ExperienceService } from "../../../Services/BookingkitApi/ExperienceService";
import {IUseWidgetExperience} from "../../../Contracts/Composables/IUseWidgetExperience";
import {Locale} from "../../../Constants/locales";


export type ModalWidgetReturnType = {
    experiences: Experience[],
}

export const UseWidgetExperience = (experienceService: ExperienceService, experienceIds?:string[]): IUseWidgetExperience=> {

    const experiences = ref<Experience[]>([]);
    const isLoading = ref<boolean>(false);
    const getExperiences = () => {
        isLoading.value = true;
        experienceService.getExperiences(experienceIds?.join(',')).then((apiExperiences) => {
            experiences.value = experienceIds ? apiExperiences.filter(x => experienceIds.includes(x.id)) : apiExperiences;
            isLoading.value = false;

        });
    }

    const getExperienceTranslatedProperty = (experience: Experience, property: 'title'|'description', language: Locale) => {
        if (experience.translations) {
            const translation = experience.translations.find((translation) => translation?.locale === language);
            if (translation)
            {
                return translation?.properties?.[property] || experience.title;
            }
        }
        return experience.title;
    }

    return {
        experiences,
        getExperiences,
        isLoading,
        getExperienceTranslatedProperty,
    };
};

export default UseWidgetExperience;