import {WidgetStateEvents} from "../../Events/Widget/State";
export const SESSION_EVENTS = {
    SESSION_CREATED: 'sessionCreated',
} as const;

type EVENTS = {
    [SESSION_EVENTS.SESSION_CREATED]: {event: 'sessionCreated', data: {sessionId: string}},
} & WidgetStateEvents;

type BookingkitEvent = EVENTS[keyof EVENTS]&{isBkEvent: true};
export class GlobalEventBus {
    emit(event: EVENTS[keyof EVENTS]) {
        window.postMessage(JSON.stringify({event: event.event, data: {...event.data, isBkEvent: true}}), '*');
    }
    listen(callback: (event: BookingkitEvent) => void) {
        window.addEventListener('message', (event) => {
            try {
                const realData = JSON.parse(event.data);
                if(realData?.data?.isBkEvent) {
                    callback(realData);
                }
            } catch {}
        });
    }
}


