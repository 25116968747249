<script setup lang="ts">
import { onErrorCaptured, onMounted,  ref,} from "vue";
import {getLogger} from "../Services/LoggingService";

import { NormalisedProps, parseProps } from "../composables/useCustomElementProps";
import {FLOATING_WIDGET_TYPE, FloatingWidgetType} from "../Contracts/WidgetTypes/FloatingWidgetType";
import "@bookingkit/coppi/dist/style.css";
import "../style.css";
import WidgetConfigurationWrapper from "./common/WidgetConfigurationWrapper.vue";


const logger = getLogger();
const props = defineProps<FloatingWidgetType>();
const ready = ref(false);

// Log errors not logged anywhere else
onErrorCaptured((error) => {
  logger.error(error);
});

// Validate and inflate widget configuration
const config: FloatingWidgetType = {
  ...parseProps(props as unknown as NormalisedProps),
  type: FLOATING_WIDGET_TYPE,
} as FloatingWidgetType;

const main = ref(null as HTMLElement | null);
const slotTarget = ref(null as Node | null);

/**
 * Use the first slot element as the trigger for the widget
 * @constructor
 */
const UseCustomElementSlotAsWidgetTrigger = () => {
  const slotElement: undefined | HTMLSlotElement | null = main.value?.querySelector('slot');
  const slotNodes = slotElement?.assignedNodes()
  const usesSlot = slotNodes && slotNodes.length > 1;
  if(usesSlot && slotElement){
    // @ts-ignore
    config.trigger = [slotElement as HTMLSlotElement];
    slotTarget.value = slotNodes[1];
  }
  ready.value = true;
}
onMounted(UseCustomElementSlotAsWidgetTrigger)



</script>

<template>
  <span ref="main">
    <slot></slot>
  </span>
  <template v-if="ready">
    <teleport v-if="slotTarget" :to="slotTarget">
      <slot></slot>
    </teleport>
    <widget-configuration-wrapper :config="config" />
  </template>
</template>

<style>
@import "@bookingkit/coppi/dist/style.css";
@import "../style.css";

</style>