import {Widget} from "../../Contracts/WidgetTypes";
import {WidgetStateEventConstructor,} from "../../Events/Widget/State";
export const useWidgetEvents = (widgetConfiguration: Widget): IUseWidgetEvents => {
    const eventEmitter = window.bookingkitServices.eventBus;
    const emit = (event: keyof WidgetStateEventConstructor, data: WidgetStateEventConstructor[typeof event]) => {
        eventEmitter.emit({event: event, data: {...data, ...widgetConfiguration}} as any);
    }

    return {
        emit
    }
}

export interface IUseWidgetEvents {
    emit: <T extends  keyof WidgetStateEventConstructor>(event: T, data: WidgetStateEventConstructor[T]) => void;
}