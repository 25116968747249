<template>
  <c-dropdown
      class="language-selector"
      :options="languages"
      :close-on-selected="true"
      @update:selectedOption="onLanguageSelect"
      :selectedOption="selectedOption"
      :max-height="300"
      open-dropdown="top"
  />
</template>
<script lang="ts" setup>
import {CDropdown} from "@bookingkit/coppi";
import languages from "../../Composables/Constants/Languages";
import {EnGB, Locale} from "../../../../Constants/locales";
import {ref} from "vue";
import {useTranslationService} from "../../../../Services/TranslationService";

const {currentLanguage, setCurrentLanguage} = useTranslationService();
const selectedOption = ref(languages.find((lang) => lang.value === currentLanguage.value ) ?? languages.find((lang) => lang.value === EnGB));
const onLanguageSelect = (option:{label:string, value:Locale, selected:false}) => {
  selectedOption.value = option;
  setCurrentLanguage(option.value);
}
</script>
<style scoped>
.language-selector {
  margin: 0 0 var(--s1) 0;
  z-index: 99;
}
</style>