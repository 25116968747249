import 'vite/modulepreload-polyfill'

import { defineCustomElement } from 'vue'
import FloatingWidgetCustomElement from "./CustomElements/FloatingWIdget.ce.vue";

import '@bookingkit/coppi/dist/style.css';
import './style.css'
import './GlobalServices/index.ts';

const FloatingWidget = defineCustomElement(FloatingWidgetCustomElement);



// Register the custom element.
// After registration, all `<my-vue-element>` tags
// on the page will be upgraded.
customElements.define('bk-floating-widget', FloatingWidget )


