import {THEMES, TriggerStyle, WidgetStyle} from "../../../Contracts/WidgetTypes/Common/TriggerStyle";
import {WidgetStyleConfiguratorError} from "../Errors/WidgetStyleConfiguratorError";

const validateStyle = (style: any): style is TriggerStyle => {
    if(style?.theme) {
        if(!THEMES.includes((style as Record<string, any>)?.theme)) {
            throw WidgetStyleConfiguratorError.fromInvalidStyle(`theme must be one of ${THEMES.join(",")}, provided: ${style?.theme}`);
        }
    }
    return true;

}
export const isValidWidgetStyle = (widgteStyle: any): widgteStyle is WidgetStyle | HTMLElement[] => {
    if(widgteStyle.length) {
        return true;
    }

    return validateStyle(widgteStyle);
}