export const POSITION_TOP_LEFT = 'top-left';
export const POSITION_TOP_RIGHT = 'top-right';
export const POSITION_BOTTOM_LEFT = 'bottom-left';
export const POSITION_BOTTOM_RIGHT = 'bottom-right';
export const POSITION_CENTER_LEFT = 'center-left';
export const POSITION_CENTER_RIGHT = 'center-right';
export const THEME_LIGHT = 'light';
export const THEME_DARK = 'dark';
export const BUTTON_SIZE_SMALL = 'small';
export const BUTTON_SIZE_MEDIUM = 'medium';
export const THEMES = [THEME_LIGHT, THEME_DARK] as const;
export type WidgetStylePosition = typeof POSITION_TOP_LEFT | typeof POSITION_TOP_RIGHT | typeof POSITION_BOTTOM_LEFT | typeof POSITION_BOTTOM_RIGHT | typeof POSITION_CENTER_LEFT | typeof POSITION_CENTER_RIGHT;
export type WidgetStyleTheme = typeof THEMES[number];
/**
 *
 * read me 123 adasd
 */
export type WidgetStyleButtonSize = typeof BUTTON_SIZE_SMALL | typeof BUTTON_SIZE_MEDIUM;
export const TICKET_ICON = 'ticket';
export const VOUCHER_ICON = 'voucher';
export type TriggerStyle =   {
    position: WidgetStylePosition,
    icon: typeof TICKET_ICON | typeof VOUCHER_ICON | false,
    label: boolean | string,
    buttonSize: WidgetStyleButtonSize,
};

export type WidgetStyle = {
    theme: WidgetStyleTheme;
}