import {WidgetAvailabilityResponse} from "@bookingkit-private/api-v4/models/widget-availability-response";
import {Availability} from "../types";
import InvalidApiV4ResponseError from "../Errors/InvalidApiV4ResponseError";
import { isValid } from 'date-fns';
import { toZonedTime } from 'date-fns-tz';
const getLocalStartDate = (localDate: string): Date => {
    const startDate = new Date(localDate);
    const eventTimeZone = localDate.slice(-6);
    return toZonedTime(startDate, eventTimeZone);
};

export const fromApiAvailability = (availability: WidgetAvailabilityResponse): Availability => {
    if(typeof availability.id !== 'string') {
        throw InvalidApiV4ResponseError.fromMissingProperty('id', 'string', availability.id);
    }
    if(typeof availability.experience_id !== 'string') {
        throw InvalidApiV4ResponseError.fromMissingProperty('experience_id', 'string', availability.experience_id);
    }
    if(typeof availability.total_capacity !== 'number') {
        throw InvalidApiV4ResponseError.fromMissingProperty('total_capacity', 'number', availability.total_capacity);
    }
    if(typeof availability.available !== 'number') {
        throw InvalidApiV4ResponseError.fromMissingProperty('available', 'number', availability.available);
    }
    if(typeof availability.start_time_utc !== 'string') {
        throw InvalidApiV4ResponseError.fromMissingProperty('start_time_utc', 'string', availability.start_time_utc);
    }
    if(typeof availability.start_time_local !== 'string') {
        throw InvalidApiV4ResponseError.fromMissingProperty('start_time_local', 'string', availability.start_time_local);
    }
    if(typeof availability.option_id !== 'string') {
        throw InvalidApiV4ResponseError.fromMissingProperty('option_id', 'string', availability.option_id);
    }
    const startTimeUtc = new Date(availability.start_time_utc);
    const startTimeLocal = new Date(availability.start_time_local);
    if (!isValid(startTimeUtc)) {
        throw InvalidApiV4ResponseError.fromInvalidProperty("start_time_utc",availability.start_time_utc);
    }
    if (!isValid(new Date(startTimeLocal))) {
        throw InvalidApiV4ResponseError.fromInvalidProperty("start_time_local",availability.start_time_local);
    }
    return {
        id: availability.id,
        optionID: availability.option_id,
        experienceId: availability.experience_id,
        totalCapacity: availability.total_capacity,
        availableSlots: availability.available,
        startTime: new Date(availability.start_time_utc),
        startTimeLocal: getLocalStartDate(availability.start_time_local),
    }

}