import {BaseWidgetConfiguration} from "../../../Contracts/WidgetTypes/BaseWidget";
import {WidgetOptionError} from "../Errors/WidgetOptionError";

const validateTrackingConfiguration = (config: any): config is BaseWidgetConfiguration['options']['tracking'] => {

    if(typeof config !== 'object')
    {
        throw WidgetOptionError.fromInvalidTracking();
    }
    if(!(config as Record<string, any>)?.gtm && !(config as Record<string, any>)?.ga) {
        throw WidgetOptionError.fromInvalidTracking();
    }
    if((config as Record<string, any>)?.gtm && typeof (config as Record<string, any>)?.gtm !== 'string') {
        throw WidgetOptionError.fromInvalidTracking();
    }
    if((config as Record<string, any>)?.ga && typeof (config as Record<string, any>)?.ga !== 'string') {
        throw WidgetOptionError.fromInvalidTracking();
    }
    return true;
}
export const areValidWidgetOptions = (config: any): config is BaseWidgetConfiguration['options'] => {
    if(config.tracking !== undefined) {
        validateTrackingConfiguration(config.tracking);
    }
    if(config.loggingLevel !== undefined && typeof config.loggingLevel !== 'string') {
        throw WidgetOptionError.fromWrongType('loggingLevel', 'string', config.loggingLevel);
    }
    if(config.loggingLevel !== undefined && !['debug' , 'warn', 'error'].includes(config.loggingLevel)) {
        throw WidgetOptionError.fromWrongType('loggingLevel', '[\'debug\' , \'warn\', \'error\'', config.loggingLevel);
    }
    if(config.crashReport !== undefined && typeof config.crashReport !== 'boolean') {
        throw WidgetOptionError.fromWrongType('crashReport', 'boolean', config.crashReport);
    }
    if(config.collectTraces !== undefined && typeof config.collectTraces !== 'boolean') {
        throw  WidgetOptionError.fromWrongType('collectTraces', 'boolean', config.collectTraces);
    }
    return true;
}

export const isValidCheckoutTheme = (theme: any): theme is BaseWidgetConfiguration['checkoutTheme'] => {
    if(typeof theme !== 'string') {
        throw WidgetOptionError.fromWrongType('checkoutTheme', 'string', theme);
    }
    return true;
}