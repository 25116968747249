import {BaseWidgetConfiguration} from "../../Contracts/WidgetTypes/BaseWidget";
import {Widget} from "../../Contracts/WidgetTypes";

export type NormalisedProps = Record<keyof BaseWidgetConfiguration, string | boolean | number>;

const mapProperty = (property: any) => {
    if(property === undefined || property === null || typeof property === 'number' || typeof property === 'boolean'){
        return property;
    }
    try {
        return JSON.parse(property);
    } catch {
        return property;
    }
}

export const parseProps = (props: NormalisedProps):Partial<Widget> => {
    const result: Partial<Widget> = {};
    for(let k  of Object.keys(props) as (keyof BaseWidgetConfiguration)[]){
        result[k] = mapProperty(props[k]);
    }
    return result;
}